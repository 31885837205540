<script setup>
import { ref, onMounted, computed } from "vue";
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

const store = useFastWay();
const loader = useLoading();
let dialog = ref(null);
let loaded = ref(false);
let projects = ref([]);
let data = ref([]);
const title = "Presupuesto";
const items = [
  {
    text: "Tablero",
    href: "/",
  },
  {
    text: "Presupuesto",
    active: true,
  },
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const viewParms = computed({
  get() {
    return store.viewParameters.projectBudget;
  }
});

const formatNumber = params => {
  let value = '';
  if (params.value != 0) {
    value = params.value.toLocaleString('es-GT', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  }
  return value;
}

const formatPercentage = params => {
  let value = '';
  if (params.value != 0) {
    value = params.value.toLocaleString('es-GT', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';
  }
  return value;
}

const columnDefs = [
{ headerName: 'Cuenta contable', field: 'accountCode', width: 130, sortable: true, pinned: 'left' },
{ headerName: 'Descripción', field: 'accountName', width: 350, sortable: true, pinned: 'left' },
{ headerName: 'Enero', children: [
      { headerName: 'Presupuesto', field: 'budget01', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution01', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage01', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Febrero', children: [
      { headerName: 'Presupuesto', field: 'budget02', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution02', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage02', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Marzo', children: [
      { headerName: 'Presupuesto', field: 'budget03', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution03', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage03', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Abril', children: [
      { headerName: 'Presupuesto', field: 'budget04', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution04', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage04', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Mayo', children: [
      { headerName: 'Presupuesto', field: 'budget05', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution05', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage05', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Junio', children: [
      { headerName: 'Presupuesto', field: 'budget06', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution06', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage06', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Julio', children: [
      { headerName: 'Presupuesto', field: 'budget07', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution07', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage07', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Agosto', children: [
      { headerName: 'Presupuesto', field: 'budget08', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution08', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage08', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Septiembre', children: [
      { headerName: 'Presupuesto', field: 'budget09', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution09', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage09', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Octubre', children: [
      { headerName: 'Presupuesto', field: 'budget10', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution10', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage10', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Noviembre', children: [
      { headerName: 'Presupuesto', field: 'budget11', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution11', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage11', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  },
  { headerName: 'Diciembre', children: [
      { headerName: 'Presupuesto', field: 'budget12', width: 100, type: 'rightAligned', valueFormatter: formatNumber},
      { headerName: 'Ejecución', field: 'execution12', width: 100, type: 'rightAligned', valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage12', width: 90, type: 'rightAligned', valueFormatter: formatPercentage, cellStyle: params => {if (params.value < 100) { return {color: 'red'} } return {color: 'green'} } }
    ]
  }
];

onMounted(async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    order: 'name',
    statusId: 'ACT'
  };
  await axios
  .post(`${api.value}/sbg/projects/list`, params)
  .then(response => {
    if (response.data.success) {
      projects.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de proyecto', response.data.message);
    }
    getData();
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async () => {
  data.value = [];
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando presupuesto de proyecto'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    limit: viewParms.value.year,
    objectId: viewParms.value.projectId
  };
  await axios
  .post(`${api.value}/sbg/projects/budget/list`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
      }
    } else {
      dialog.value.show('error', 'Error interno al cargar presupuesto de proyecto', response.data.message);
    }
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al cargar presupuesto de proyecto', error.message);
  });
}

const onFilterChange = () => {
  viewParms.value.currentPage = 1;
  getData();
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row mb-2">
                        <div class="d-inline-block mr-1" style="width: 400px;">
                          <DropDownListComponent v-model:value="viewParms.projectId" floatLabelType="Auto" placeholder="Proyecto" highlight=true :dataSource="projects" :fields="{text: 'name', value: 'id'}" :showClearButton='false' :change="onFilterChange"/>
                        </div>
                        <div class="col-1">
                          <NumericTextBoxComponent v-model:value="viewParms.year" floatLabelType="Auto" placeholder="Año" format="n0" :min="2020" :showSpinButton="false"/>
                        </div>
                        <div class="col d-flex justify-content-start">
                          <b-button variant="outline-success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Recargar" @click="getData">
                              <i class="las la-sync"></i>
                          </b-button>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%; height: 700px;"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="data"
                        :accentedSort="true"
                    >
                    </ag-grid-vue>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
  </Layout>
</template>

<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

#input-container .e-input-group {
  margin: 30px 0;
}

.e-input-group-icon:before {
  font-family: e-icons;
}

.e-input-group .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group.e-small .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group-icon.e-input-clear:before {
  content: "\e7a7";
}

.e-input-group-icon.e-input-search:before {
  content: "\e993";
}

.number-cell {
  text-align: right;
}
</style>
<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import Confirm from '@/views/components/Confirm.vue';
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";
import { DropDownListComponent, MultiSelectComponent } from "@syncfusion/ej2-vue-dropdowns";

const router = useRouter();
const route = useRoute();
const store = useFastWay();
const loader = useLoading();
// let gridApi = null;
let id = ref(0);
let loaded = ref(false);
let editing = ref(false);
let dialog = ref(null);
let dialogDelete = ref(null);
let confirm = ref(null);
let data = ref(null);
let programs = ref([]);
let statuses = ref([]);
let users = ref([]);
let achievements = ref([]);
let data_local = ref({
  id: 0,
  analitycAccount: '',
  name: '',
  analitycAccountId: 0,
  programId: '',
  statusId: 'ACT',
  managers: [],
  users: []
});
const title = "Proyectos";
const items = [
  {
    text: "Tablero",
    href: "/",
  },
  {
    text: "Proyectos",
    href: "/sbg/projects/projects",
  },
  {
    text: "Proyecto",
    active: true,
  }
];
const columnDefs = [
  {
    headerName: 'Logros del proyecto',
    field: 'description',
    flex: 1,
    sortable: false,
    cellRenderer: params => {
      let rend = '';
      if (params.data.level <= 1) {
        rend = `<h5>${params.data.description}</h5>`;
      } else if (params.data.level == 2) {
        rend = `&nbsp;&nbsp;&nbsp;<b>${params.data.description}</b>`;
      } else if (params.data.level == 3) {
        rend = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${params.data.description}`;
      } else {
        rend = `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${params.data.description}`;
      }
      return rend;
    }
  }
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

onMounted(async () => {
  id.value = parseInt(route.params.id);
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  await axios
  .post(`${api.value}/lists/get_list`, {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    list: 'ESTADOPROYECTO'
  })
  .then(response => {
    if (response.data.success) {
      statuses.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar estados de proyecto', response.data.message);
    }
    return axios.post(`${api.value}/lists/get_list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      list: 'PROGRAMAPROYECTO'
    })
  })
  .then(response => {
    if (response.data.success) {
      programs.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar lista de programas', response.data.message);
    }
    return axios.post(`${api.value}/users/list`, {
      userId: store.userInfo.userId,
      token: store.userInfo.token,
      order: 'name'
    })
  })
  .then(response => {
    if (response.data.success) {
      users.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar lista de usuarios', response.data.message);
    }
    getData(id.value);
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async (id) => {
  if (id == '0') {
    loaded.value = true;
    editing.value = true;
    achievements.value = [];
  } else {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando datos de proyecto'});
    await axios
    .post(`${api.value}/sbg/projects/data`, {id, context: store.userInfo})
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          data.value = response.data.data;
          data_local.value = JSON.parse(JSON.stringify(response.data.data));
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar proyecto', response.data.message);
      }
      return axios.post(`${api.value}/sbg/projects/achievements`, {userId: store.userInfo.userId, token: store.userInfo.token, projectId: id});
    })
    .then(response => {
      if (response.data.success) {
        if (response.data.data) {
          achievements.value = response.data.data.filter(a => a.year == 2025);
        }
      } else {
        dialog.value.show('error', 'Error interno al cargar logros de proyecto', response.data.message);
      }
      loaded.value = true;
      loading.hide();
    })
    .catch(error => {
      loaded.value = true;
      loading.hide();
      dialog.value.show('error', `Error al cargar ubicación ${id}`, error.message);
    });
  }
};

const resetData = () => {
  if (id.value == '0') {
    router.push({name: 'projects'});
  } else {
    data_local.value = JSON.parse(JSON.stringify(data.value));
    editing.value = false;
  }
};

const validateForm = () => {
  let validated = false;
  if (!data_local.value.analyticAccount || data_local.value.analyticAccount == '') {
    dialog.value.show('error', 'Error al guardar proyecto', 'Debe especificar un código');
  } else if (!data_local.value.name || data_local.value.name == '') {
    dialog.value.show('error', 'Error al guardar proyecto', 'Debe especificar nombre del proyecto');
  } else {
    validated = true;
  }
  return validated;
};

const save = async () => {
  if (validateForm()) {
    const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando datos de proyecto'});
    data_local.value.id = id.value;
    data_local.value.context = store.userInfo;
    data_local.value.createdBy = store.userInfo.userId;
    // const apivalue = 'http://localhost:8080/v2';
    await axios
    .post(`${api.value}/sbg/projects/save`, data_local.value)
    .then(response => {
      loading.hide();
      if (response.data.success) {
        data.value = JSON.parse(JSON.stringify(data_local.value));
        editing.value = false;
        dialog.value.show('success', 'Información', 'Datos han sido actualizados');
        if (id.value== 0) {
          router.push({
            name: 'project',
            params: {id: response.data.data.id}
          });
        }
      } else {
        dialog.value.show('error', 'Error al actualizar', response.data.message);
      }
    })
    .catch(error => {
      loading.hide();
      dialog.value.show('error',  `Error al actualizar ubicación ${id.value}`, error.message);
    });
  }
};

const remove = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Eliminando proyecto'});
  const parms = {
    id: id.value,
    context: store.userInfo,
    createdBy: store.userInfo.userId
  };
  await axios
  .post(`${api.value}/sbg/projects/delete`, parms)
  .then(response => {
    loading.hide();
    if (response.data.success) {
      dialogDelete.value.show('success', 'Información', 'Proyecto fue eliminado correctamente');
    } else {
      dialog.value.show('error', 'Error al eliminar', response.data.message);
    }
  })
  .catch(error => {
    loading.hide();
    dialog.value.show('error', `Error al eliminar proyecto ${id.value}`, error.message);
  });
};

// const onGridReady = params => {
//   gridApi = params.api;
// }

const add = () => {
  router.push({
    name: 'project',
    params: {id: 0}
  });
  id.value = 0;
  data_local.value = {
    id: 0,
    analitycAccount: '',
    analitycAccountId: 0,
    programId: '',
    statusId: 'ACT',
    managers: [],
    users: []
  };
  editing.value = true;
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
          <div class="card mb-2">
              <div class="card-body">
                  <div class="d-flex justify-content-between align-items-center align-middle row ms-0 me-0 mb-2">
                      <div v-if="!editing" class="col d-flex justify-content-start">
                        <b-button variant="success" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Agregar proyecto" @click="add"><i class="las la-plus label-icon"></i> Agregar</b-button>
                      </div>
                      <div class="col"/>
                      <div class="input-group col justify-content-end">
                        <b-button v-if="editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Guardar cambios efectuados" @click="save"><i class="las la-save label-icon"></i> Guardar</b-button>
                        <b-button v-if="editing" variant="warning" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Descartar cambios efectuados" @click="resetData"><i class="las la-undo label-icon"></i> Cancelar</b-button>
                        <b-button v-if="!editing" variant="primary" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Editar información" @click="editing = true"><i class="las la-edit label-icon"></i> Editar</b-button>
                        <b-button v-if="!editing" variant="danger" class="btn-label waves-effect waves-light" v-b-tooltip.hover title="Eliminar proyecto" @click="confirm.show('Confirme', '¿Está seguro de eliminar este proyecto?')"><i class="las la-trash label-icon"></i> Eliminar</b-button>
                      </div>
                  </div>
                  <hr>
                  <div class="row mb-4">
                    <div class="col-4">
                      <div class="form-floating">
                        <input type="text" class="form-control form-control-lg" :class="{'bg-light': !editing, 'bg-white': editing}" id="role" v-model="data_local.analyticAccount" placeholder="Código" :disabled="!editing"/>
                        <label for="role">Código</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <TextBoxComponent v-model:value="data_local.name" floatLabelType="Auto" placeholder="Nombre" :disabled="!editing"/>
                    </div>
                    <div class="col-3">
                      <DropDownListComponent
                        v-model:value="data_local.programId"
                        floatLabelType="Auto"
                        placeholder="Programa"
                        highlight=true
                        :dataSource="programs"
                        :fields="{text: 'value', value: 'code'}"
                        :enabled="editing"
                      />
                    </div>
                    <div class="col-2">
                      <NumericTextBoxComponent v-model:value="data_local.analyticAccountId" floatLabelType="Auto" placeholder="ID Cuenta analítica Odoo" :disabled="!editing" format="n0" :min="1" :showSpinButton="false"/>
                    </div>
                    <div class="col-1">
                      <DropDownListComponent
                        v-model:value="data_local.statusId"
                        floatLabelType="Auto"
                        placeholder="Estado"
                        highlight=true
                        :dataSource="statuses"
                        :fields="{text: 'value', value: 'code'}"
                        :enabled="editing"
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <MultiSelectComponent
                        v-model:value="data_local.managers"
                        floatLabelType="Auto"
                        placeholder="Administradores"
                        :dataSource="users"
                        mode="Box"
                        :fields="{text: 'name', value: 'id'}"
                        :enabled="editing"
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <MultiSelectComponent
                        v-model:value="data_local.users"
                        floatLabelType="Auto"
                        placeholder="Gestores"
                        :dataSource="users"
                        mode="Box"
                        :fields="{text: 'name', value: 'id'}"
                        :enabled="editing"
                      />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <TextBoxComponent v-model:value="data_local.comments" floatLabelType="Auto" placeholder="Comentarios" :enabled="editing" :multiline="true"/>
                    </div>
                  </div>
              </div>
          </div>
          <div class="card">
            <div class="card-body">
              <ag-grid-vue
                style="width: 100%; height: 500px;"
                class="ag-theme-balham"
                :columnDefs="columnDefs"
                :rowData="achievements"
                :accentedSort="true"
              >
              </ag-grid-vue>
            </div>
          </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
    <Dialog ref="dialogDelete" @accept="router.push({name: 'projects'});"/>
    <Confirm ref="confirm" @accept="remove"/>
  </Layout>
</template>

<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

.e-input-group .e-input[disabled], .e-input-group.e-control-wrapper .e-input[disabled], .e-input-group.e-disabled, .e-input-group.e-control-wrapper.e-disabled, .e-float-input input[disabled], .e-float-input.e-control-wrapper input[disabled], .e-float-input textarea[disabled], .e-float-input.e-control-wrapper textarea[disabled], .e-float-input.e-disabled, .e-float-input.e-control-wrapper.e-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.75);
    color: rgba(0, 0, 0, 0.75);
}
</style>
<script setup>
import { ref, onMounted, computed } from "vue";
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

const store = useFastWay();
const loader = useLoading();
let dialog = ref(null);
let loaded = ref(false);
let projects = ref([]);
let data = ref([]);
let totalRowCount = ref(0);
const title = "Metas";
const items = [
  {
    text: "Tablero",
    href: "/",
  },
  {
    text: "Metas",
    active: true,
  },
];

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const viewParms = computed({
  get() {
    return store.viewParameters.projectGoals;
  }
});

const formatNumber = params => {
  let value = '';
  if (typeof params.value != 'undefined') {
    if (params.value != 0) {
      value = params.value.toLocaleString('es-GT', {minimumFractionDigits: 0, maximumFractionDigits: 0});
    }
  }
  return value;
}

const formatPercentage = params => {
  let value = '';
  if (typeof params.value != 'undefined') {
    if (params.value != 0) {
      value = params.value.toLocaleString('es-GT', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + '%';
    }
  }
  return value;
}

const columnDefs = [
  {
    headerName: 'Meta',
    field: 'description',
    cellStyle: {'white-space': 'normal'},
    autoHeight: true,
    width: 400,
    sortable: false,
    pinned: 'left',
    cellRenderer: params => {
      let rend = '';
      if (params.data.level <= 1) {
        rend = `<h5>${params.data.description}</h5>`;
      } else if (params.data.level == 2) {
        rend = `<b style="margin-left: 10px;">${params.data.description}</b>`;
      } else if (params.data.level == 3) {
        rend = `<div style="margin-left: 20px;">${params.data.description}</div>`;
      } else {
        rend = `<div style="margin-left: 30px;">${params.data.description}</div>`;
      }
      return rend;
    }
  },
  { headerName: 'TOTAL', pinned: 'left', children: [
      { headerName: 'Meta', field: 'goalTotal', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'executiontotal', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentageTotal', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Enero', children: [
      { headerName: 'Meta', field: 'goal01', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution01', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage01', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Febrero', children: [
      { headerName: 'Meta', field: 'goal02', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution02', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage02', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Marzo', children: [
      { headerName: 'Meta', field: 'goal03', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution03', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage03', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Abril', children: [
      { headerName: 'Meta', field: 'goal04', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution04', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage04', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Mayo', children: [
      { headerName: 'Meta', field: 'goal05', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution05', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage05', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Junio', children: [
      { headerName: 'Meta', field: 'goal06', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution06', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage06', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Julio', children: [
      { headerName: 'Meta', field: 'goal07', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution07', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage07', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Agosto', children: [
      { headerName: 'Meta', field: 'goal08', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution08', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage08', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Septiembre', children: [
      { headerName: 'Meta', field: 'goal09', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution09', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage09', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Octubre', children: [
      { headerName: 'Meta', field: 'goal10', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution10', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage10', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Noviembre', children: [
      { headerName: 'Meta', field: 'goal11', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution11', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage11', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  },
  { headerName: 'Diciembre', children: [
      { headerName: 'Meta', field: 'goal12', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: 'Ejecución', field: 'execution12', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatNumber },
      { headerName: '%', field: 'percentage10', width: 90, cellStyle: params => { if (params.data.isDetail) { if (params.value < 100) return {color: 'red', textAlign: 'right'}; else return {color: 'green', textAlign: 'right'}; } else { return {display: 'none'}; } }, valueFormatter: formatPercentage }
    ]
  }
];

onMounted(async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token
  };
  await axios
  .post(`${api.value}/sbg/projects/user_list`, params)
  .then(response => {
    if (response.data.success) {
      projects.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar proyectos del usuario', response.data.message);
    }
    getData();
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async () => {
  data.value = [];
  totalRowCount.value = 0;
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando metas de proyecto'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    year: viewParms.value.year,
    projectId: viewParms.value.projectId
  };
  // const apivalue = 'http://localhost:8080/v2';
  await axios
  .post(`${api.value}/sbg/projects/goals/list`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
        if (response.data.data.length > 0) {
          totalRowCount.value = response.data.data[0].totalRowCount;
        }
      }
    } else {
      dialog.value.show('error', 'Error interno al cargar metas de proyecto', response.data.message);
    }
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al cargar metas de proyecto', error.message);
  });
}

const b64toBlob = (b64Data, contentType, sliceSize) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

const getFile = async () => {
  data.value = [];
  totalRowCount.value = 0;
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Generando hoja electrónica de metas de proyecto'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    year: viewParms.value.year,
    projectId: viewParms.value.projectId
  };
  // const apivalue = 'http://localhost:8080/v2';
  await axios
  .post(`${api.value}/sbg/projects/goals/list_excel`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        let projectName = '';
        const project = projects.value.filter(item => item.id == viewParms.value.projectId);
        if (project.length > 0) {
          projectName = project[0].name;
        }
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(b64toBlob(response.data.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
        link.download = `Metas de proyecto ${projectName} a ${viewParms.value.year}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        getData();
      }
    } else {
      dialog.value.show('error', 'Error interno al generar reporte a Excel', response.data.message);
    }
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al generar reporte a Excel', error.message);
  });
}

const onFilterChange = () => {
  viewParms.value.currentPage = 1;
  getData();
}

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row mb-2">
                        <div class="d-inline-block mr-1" style="width: 400px;">
                          <DropDownListComponent v-model:value="viewParms.projectId" floatLabelType="Auto" placeholder="Proyecto" highlight=true :dataSource="projects" :fields="{text: 'name', value: 'id'}" :showClearButton='false' :change="onFilterChange"/>
                        </div>
                        <div class="col-1">
                          <NumericTextBoxComponent v-model:value="viewParms.year" floatLabelType="Auto" placeholder="Año" format="n0" :min="2020" :showSpinButton="false"/>
                        </div>
                        <div class="col d-flex justify-content-start">
                          <b-button variant="outline-success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Recargar" @click="getData">
                            <i class="las la-sync"></i>
                          </b-button>
                          <b-button variant="success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Descargar archivo" @click="getFile">
                              <i class="las la-file-excel"></i>
                          </b-button>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%; height: 700px;"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="data"
                        :accentedSort="true"
                    >
                    </ag-grid-vue>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
  </Layout>
</template>

<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

#input-container .e-input-group {
  margin: 30px 0;
}

.e-input-group-icon:before {
  font-family: e-icons;
}

.e-input-group .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group.e-small .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group-icon.e-input-clear:before {
  content: "\e7a7";
}

.e-input-group-icon.e-input-search:before {
  content: "\e993";
}

.number-cell {
  text-align: right;
}
</style>
<script setup>
import { ref, onMounted, computed } from "vue";
import axios from 'axios';
import { useLoading } from 'vue-loading-overlay'
import Layout from "@/layouts/mainfw.vue";
import PageHeader from "@/components/page-header";
import { useFastWay } from "@/state/modules/fastway";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import Dialog from '@/views/components/Dialog.vue';
import {DropDownListComponent} from "@syncfusion/ej2-vue-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-vue-inputs";

const store = useFastWay();
const loader = useLoading();
let dialog = ref(null);
let loaded = ref(false);
let projects = ref([]);
let data = ref([]);
let totalRowCount = ref(0);
const title = "Ejecución de metas";
const items = [
  {
    text: "Tablero",
    href: "/",
  },
  {
    text: "Ejecución de metas",
    active: true,
  },
];
const months = ref([
{ id:  1, name: 'Enero' },
{ id:  2, name: 'Febrero' },
{ id:  3, name: 'Marzo' },
{ id:  4, name: 'Abril' },
{ id:  5, name: 'Mayo' },
{ id:  6, name: 'Junio' },
{ id:  7, name: 'Julio' },
{ id:  8, name: 'Agosto' },
{ id:  9, name: 'Septiembre' },
{ id: 10, name: 'Octubre' },
{ id: 11, name: 'Noviembre' },
{ id: 12, name: 'Diciembre' },
])

const api = computed({
    get() {
      return store.parameters.apiURL;
    }
});

const viewParms = computed({
  get() {
    return store.viewParameters.projectGoalsExecution;
  }
});

const columnDefs = [
  {
    headerName: 'Logro',
    field: 'description',
    cellStyle: {'white-space': 'normal'},
    autoHeight: true,
    width: 500,
    sortable: false,
    pinned: 'left',
    cellRenderer: params => {
      let rend = '';
      if (params.data.level <= 1) {
        rend = `<h5>${params.data.description}</h5>`;
      } else if (params.data.level == 2) {
        rend = `<b style="margin-left: 10px;">${params.data.description}</b>`;
      } else if (params.data.level == 3) {
        rend = `<div style="margin-left: 20px;">${params.data.description}</div>`;
      } else {
        rend = `<div style="margin-left: 30px;">${params.data.description}</div>`;
      }
      return rend;
    }
  },
  { headerName: 'Meta', field: 'goal', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } } },
  { headerName: 'Ejecución', field: 'execution', width: 100, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'right'}; } else { return {display: 'none'}; } }, editable: true, cellEditor: 'agNumberCellEditor' },
  { headerName: 'Comentarios', field: 'comments', flex: 1, cellStyle: params => { if (params.data.isDetail) { return {textAlign: 'left'}; } else { return {display: 'none'}; } }, editable: true, cellEditor: 'agTextCellEditor' }
];

onMounted(async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando catálogos'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token
  };
  await axios
  .post(`${api.value}/sbg/projects/user_list`, params)
  .then(response => {
    if (response.data.success) {
      projects.value = response.data.data;
    } else {
      dialog.value.show('error', 'Error al cargar proyectos del usuario', response.data.message);
    }
    getData();
    loading.hide();
  })
  .catch(error => {
      let errorMessage = '';
      if (error.message) {
        errorMessage = error.message;
      } else if (error.response) {
        errorMessage = error.response.data.ErrorMessage;
      } else {
        errorMessage = 'Error de conectividad al cargar catálogo';
      }
      loading.hide();
      dialog.value.show('error', 'Error al cargar catálogos', errorMessage);
  });
});

const getData = async () => {
  data.value = [];
  totalRowCount.value = 0;
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Cargando metas de proyecto'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    projectId: viewParms.value.projectId,
    year: viewParms.value.year,
    month: viewParms.value.month
  };
  // const apivalue = 'http://localhost:8080/v2';
  await axios
  .post(`${api.value}/sbg/projects/goals/month`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
      }
    } else {
      dialog.value.show('error', 'Error interno al cargar metas de proyecto', response.data.message);
    }
    loading.hide();
    loaded.value = true;
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al cargar metas de proyecto', error.message);
  });
}

const save = async () => {
  const loading = loader.show({loader: 'bars', color: '#0000FF'}, {after: () => 'Guardando ejecución de metas de proyecto'});
  const params = {
    userId: store.userInfo.userId,
    token: store.userInfo.token,
    projectId: viewParms.value.projectId,
    year: viewParms.value.year,
    month: viewParms.value.month,
    execution: data.value
  };
  // const apivalue = 'http://localhost:8080/v2';
  await axios
  .post(`${api.value}/sbg/projects/goals/save_execution`, params)
  .then(response => {
    if (response.data.success) {
      if (response.data.data) {
        data.value = response.data.data;
      }
    } else {
      dialog.value.show('error', 'Error interno al guardar ejecución de metas de proyecto', response.data.message);
    }
    loading.hide();
  })
  .catch(error => {
    loaded.value = true;
    loading.hide();
    dialog.value.show('error', 'Error al guardar ejecución de metas de proyecto', error.message);
  });
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center align-middle row mb-2">
                        <div class="d-inline-block mr-1" style="width: 300px;">
                          <DropDownListComponent v-model:value="viewParms.projectId" floatLabelType="Auto" placeholder="Proyecto" highlight=true :dataSource="projects" :fields="{text: 'name', value: 'id'}" :showClearButton='false'/>
                        </div>
                        <div class="col-1">
                          <NumericTextBoxComponent v-model:value="viewParms.year" floatLabelType="Auto" placeholder="Año" format="n0" :min="2020" :showSpinButton="false"/>
                        </div>
                        <div class="col-1">
                          <DropDownListComponent
                            v-model:value="viewParms.month"
                            floatLabelType="Auto"
                            placeholder="Mes"
                            highlight=true
                            :dataSource="months"
                            :fields="{text: 'name', value: 'id'}"
                          />
                        </div>
                        <div class="col-1 d-flex justify-content-start">
                          <b-button variant="outline-success" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Recargar" @click="getData">
                              <i class="las la-sync"></i>
                          </b-button>
                        </div>
                        <div class="col">
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                          <b-button variant="primary" class="btn-icon waves-effect waves-light me-1" v-b-tooltip.hover title="Guardar" @click="save">
                              <i class="las la-save"></i>
                          </b-button>
                        </div>
                    </div>
                    <ag-grid-vue
                        style="width: 100%; height: 700px;"
                        class="ag-theme-balham"
                        :columnDefs="columnDefs"
                        :rowData="data"
                        :accentedSort="true"
                    >
                    </ag-grid-vue>
                </div>
            </div>
        </div>
    </div>
    <Dialog ref="dialog"/>
  </Layout>
</template>

<style scoped>
@import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";
@import "../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";

#input-container .e-input-group {
  margin: 30px 0;
}

.e-input-group-icon:before {
  font-family: e-icons;
}

.e-input-group .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group.e-small .e-input-group-icon.e-input-clear .e-input-group-icon.e-input-search {
  font-size: 12px;
}

.e-input-group-icon.e-input-clear:before {
  content: "\e7a7";
}

.e-input-group-icon.e-input-search:before {
  content: "\e993";
}

.number-cell {
  text-align: right;
}
</style>